$color_text: white !default;
$color_background: black !default;
$color_base: black !default;
$color_1: rgb(21,21,21) !default; //scale_color($color_base, $lightness: 7%) !default;
$color_2: scale_color($color_base, $lightness: 10%) !default;
$color_3: scale_color($color_base, $lightness: 15%) !default;
$color_4: scale_color($color_base, $lightness: 20%) !default;
$color_5: scale_color($color_base, $lightness: 25%) !default;
$color_6: scale_color($color_base, $lightness: 30%) !default;
$color_drag1: #cfe8ff !default;
$color_drag2: #b7d1b5 !default;

$font-size: medium !default;
$font-family: Roboto, Arial, sans-serif !default;

@mixin tabset_mixin {
    background-color: $color_1;
}

@mixin tabset_tabbar_mixin {
    background-color: $color_1;
}

@mixin tabset_header_mixin {
    background-color: $color_1;
}

@mixin tabset_selected_mixin {
    background-image: linear-gradient($color_background, $color_4);
}

@mixin tabset_header_mixin {
    background-color: $color_1;
    box-shadow: inset 0 0 3px 0 rgba(136, 136, 136, 0.54);
}

@mixin tabset_selected_mixin {
    background-image: linear-gradient($color_background, $color_4);
}

@mixin tabset_maximized_mixin {
    background-image: linear-gradient($color_6, $color_2);
}

@mixin tab_top_mixin {
    box-shadow: inset -2px 0px 5px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

@mixin tab_bottom_mixin {
    box-shadow: inset -2px 0px 5px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}


@mixin tab_button_mixin {

}

@mixin tab_button_selected_mixin {
    background-color: $color_1;
    color: $color_text;
}

@mixin tab_button_unselected_mixin {
    color: gray;
}

@mixin tab_button_hovered_mixin {
    background-color: $color_1;
    color: $color_text;
}

@mixin border_mixin {
    background-color: $color_1;
}

@mixin border_button_mixin {
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, .15);
    border-radius: 3px;
    // background-color: $color_1;
    padding: 4px;

}

@mixin border_button_selected_mixin {
    // background-color: $color_1;
    color: $color_text;
}

@mixin border_button_unselected_mixin {
    color: gray;
}

@mixin border_button_hovered_mixin {
    background-color: $color_1;
    color: $color_text;
}

@mixin splitter_mixin {
    background-color: $color_2;
}

@mixin splitter_hover_mixin {
    background-color: $color_4;
}

@mixin splitter_drag_mixin {
    border-radius: 5px;
    background-color: $color_5;
}

@mixin splitter_border_mixin {
}


@import "flexlayout-react/style/_base";
