.flexlayout__border_top {
    display: none;
}
.flexlayout__tab {
    overflow: hidden;
}
nav.toolbar {
    z-index: 4;
    height: 48px;
}
nav.navbar {
    background-color: rgb(21,21,21);
    border-bottom: 1px solid rgb(36, 36, 36);
    padding: 0 8px;
}

.btn-menu, .btn-menu:focus, .btn-menu:active {
    outline: none;
    box-shadow: none;
}

.toolbar .btn-menu {
    margin: 0 8px 0 5px;
}

.btn-menu {
    border: 1px solid transparent;
    border-radius: 2px;
    transition: border 0.3s ease 0s;
    padding: 0;
    height: 28px;
    width: 28px;
    color: rgb(153, 153, 153);
}
.btn-menu:hover {
    border-color: rgba(230, 230, 230, 0.125);
    color: rgb(230, 230, 230);
}