body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-dialog {
  color: rgb(64, 64, 64);
}

.btn, .btn:focus, .btn:active, .btn:active:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn:active:focus {
  background: #222 !important;
}

[data-reach-popover] {
  z-index: 100;
}
[data-reach-menu] [data-reach-menu-list],
[data-reach-menu] [data-reach-menu-items] {
  min-width: 100px;
  background-color: rgb(21, 21, 21);
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 4px, rgb(0 0 0 / 24%) 0px 16px 32px;
  overflow: hidden;
  border: 1px solid rgb(36, 36, 36);
  transform: translateY(4px);
  padding: 0px;
  font-size: 13px;
}

[data-reach-menu] [data-reach-menu-item] {
    padding: 8px;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    cursor: pointer;
    outline: none;
    color: rgb(230, 230, 230);
    font: inherit;
}

[data-reach-menu] [data-reach-menu-item][data-selected],
[data-reach-menu] [data-reach-menu-item]:hover {
  outline: none;
  background-color: rgb(36, 36, 36);
  color: rgb(255, 255, 255);
}