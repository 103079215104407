.editor-container {
  width: 100%;
  height: 100%;
  display: flex;
  user-select: none;
}
.palette {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: black;
  z-index: 1;
}

.palette .content {
  display: grid;
  grid-template-columns: [col1-start] min-content [col2-start] auto [col3-start];
  grid-template-rows: min-content;
  grid-gap: 2px;
  margin-bottom: 2px;
}
.palette .header {
  display: flex;
  justify-content: space-between;
  padding: 2px 0 ;
  font-size: 1.1em;
  background-color: rgb(255, 255, 255, 0.1);
  cursor: pointer;
}

.palette button {
  background: linear-gradient(#ddd, #999);
  height: 22px;
  width: 22px;
  border: none !important;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem !important;
  font-weight: bold;
  padding: 1px !important;
  color: #333 !important;
  position: relative; /* provide anchor for ".category-expand::after" element which is position: absolute */ 
}
.palette button.active {
  color: white !important;
  background: linear-gradient(#f00, #a00);
}

.editor {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 27, 54);
  background-image: linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px), linear-gradient(to bottom,  rgba(255,255,255,0.05) 1px, transparent 1px);
  background-position: 50% 50%;
  background-size: 16px 16px;
}

.editor rect.selection {
  fill: rgba(0, 192, 255, 0.2);
  stroke: #00c0ffaa; 
  stroke-width: 1px;
}

.editor .wire > .line { stroke: rgba(0, 78, 174, 0.8); stroke-width: 3px; }
.editor .wire > .path1 { stroke: rgba(0, 78, 174, 0.8); stroke-width: 3px; }
.editor .wire > .path2 { stroke: rgba(68, 111, 213, 1); stroke-width: 1px; }
.editor .wire.selected > .line { stroke: rgba(56, 103, 203, 1); }
.editor .wire.selected > .path1 { stroke: rgba(56, 103, 203, 1); }
.editor .wire.selected > .path2 { stroke: rgba(110, 146, 253, 1); }

.editor .module.preview { opacity: 0.5; pointer-events: none !important; }
.editor .module [data-input-id].label { text-anchor: end; }
.editor .module [data-output-id].label { text-anchor: start; }
.editor .module rect.inner { stroke: rgba(0,0,0,0.66); fill: rgba(0,0,0,0.32); }
.editor .module text.inner { fill: "black" }

.editor text { transform: scale(.0625); font-size: 10px; text-rendering: optimizeSpeed; text-anchor: middle; dominant-baseline: middle; }
.editor .label { opacity: 0.4; }

.editor > div {
  width: 64px;
  height: 64px;
  top: 50%;
  left: 50%;
  position: relative;
}

.editor > div > svg {
  width: 100%;
  height: 100%;
  overflow: visible;

  top: -50%;
  left: -50%;
  position: relative;

  /* by default disabled - will be enabled for children that have the event class */
  pointer-events: none;
}
.editor .events { pointer-events: auto; }
.editor .module:hover .label,
.editor .module.selected .label { display: block; }
.editor .module rect.outer { fill:rgb(100,100,100); }

.editor .wire {stroke-linejoin: round; fill: none; }

.editor .module.selected rect.outer,
/* hover feedback of module connector tool - could look like a selected module */
.editor.module-connector-tool .module:hover rect.outer
{
  fill:rgb(161,161,161);
}


.editor .module .inner {
  font-size: 40px;
}
.editor .simple-diagram .complex { display:none !important; }
.editor .complex { display: block; }
.editor .simple-diagram .simple { display: block; }
.editor .simple { display: none; }
.editor .label { display: none; }
/* 
.editor::-webkit-scrollbar { width: 10px; height: 10px; }
.editor::-webkit-scrollbar-thumb { background: rgba(128,128,128,0.6); border-radius: 4px; }
.editor::-webkit-scrollbar-track,
.editor::-webkit-scrollbar-corner { background: transparent; } */
