.finder {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.finder[data-mode="remote"] {
    background-color: rgba(0,0,255,0.05);
} 
.finder[data-mode="changes"] {
    background-color: rgba(0,255,0,0.05);
} 