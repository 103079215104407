// Customizing default variables before the import of boostrap
$light-blue: #004683;
$theme-colors: (
    'primary': #0074d9,
    'special': #000,
);

$body-color: #dddddd;
$font-size-base: 0.6875rem; // this means default font-size of 11px
$font-weight-normal: normal;
$font-weight-bold: bold;
$line-height-base: 1;
$enable-transitions: false;
$enable-shadows: false;

// Finder
$list-group-bg: inherit;
// $list-group-border-width: 0px;
$list-group-item-padding-y: 3px;
$list-group-item-padding-x: 6px;

// Does not work; so we modify it at the bottom
$nav-tabs-active-link-hover-bg: white;
$nav-tabs-justified-active-link-border-color: white;

$font-family-sans-serif:
  "Arial",
  // Safari for OS X and iOS (San Francisco)
  -apple-system,
  // Chrome < 56 for OS X (San Francisco)
  BlinkMacSystemFont,
  // Windows
  "Segoe UI",
  // Android
  "Roboto",
  // Basic web fallback
  "Helvetica Neue", Arial, sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;



@import "~bootstrap/scss/bootstrap";
