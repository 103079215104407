.flexlayout__border_button_leading {
    transform: rotate(90deg);
}


#root .flexlayout__border_button {
    border-radius: 0;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
}
#root .flexlayout__border_button--selected {
    border-top-color: white;
}