#workspace-list, .blue-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0, 32, 64) !important;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px), linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px);
    background-size: 16px 16px;
    background-position: calc(50% + 0px) calc(50% + 0px);
}

#workspace-list .modal-body {
    background-color: #f7f7f7;
}


#workspace-list .modal-body {
    background-color: #f7f7f7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 0px !important;
}

#workspace-list .container {
    padding: 0.75rem;
}

#workspace-list .modal-header {
    padding: 0;
    align-items: flex-end;
}
#workspace-list .modal-header .modal-title {
    padding: 1rem;
}
#workspace-list .modal-header .nav {
    margin-bottom: -1px;
}
#workspace-list .list-group {
    background-color: white;
}

#workspace-list .nav-tabs .nav-link.active, #workspace-list  .nav-tabs .nav-item.show .nav-link {
    background-color: #f7f7f7;
    border-bottom-color: #f7f7f7;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: white;
    border-color: #dee2e6 #dee2e6 white;
}

#workspace-list .list-group-item {
    color: #495057;
}

#workspace-list .list-group-item.active {
    color: white;
}

#workspace-list small {
    font-size: 80% !important;
}

#workspace-list .list-group-item .delete-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: #dc3545 !important;
    padding: 0.2rem;
    background-color: transparent;
    border-color: transparent;   
}
#workspace-list .list-group-item.active .delete-button {
    background-color: white;
    border-color: white;
}
#workspace-list .list-group-item .delete-button:hover,
#workspace-list .list-group-item.active .delete-button:hover {
    color: white !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
}

#workspace-list .list-group-item .delete-button:focus,
#workspace-list .list-group-item.active .delete-button:focus {
    color: white !important;
    background-color: #c82333 !important;
    border-color: #bd2130 !important;
}

